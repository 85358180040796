import React, { useEffect } from 'react';

const HalloweenBats = () => {
  useEffect(() => {
    const options = {
      image: 'https://raw.githubusercontent.com/Artimon/jquery-halloween-bats/master/bats.png', // Path to the image.
      zIndex: 10000, // The z-index you need.
      amount: 15, // Bat amount.
      width: 35, // Image width.
      height: 20, // Animation frame height.
      frames: 4, // Amount of animation frames.
      speed: 20, // Higher value = faster.
      flickering: 20 // Higher value = slower.
    };

    const bats = [];
    const intervals = [];
    const innerWidth = window.innerWidth;
    const innerHeight = window.innerHeight;

    const randomPosition = (direction) => {
      const screenLength = direction === 'horizontal' ? innerWidth : innerHeight;
      const imageLength = direction === 'horizontal' ? options.width : options.height;
      return Math.random() * (screenLength - imageLength);
    };

    const createBat = () => {
      let x = randomPosition('horizontal');
      let y = randomPosition('vertical');
      let tx = randomPosition('horizontal');
      let ty = randomPosition('vertical');
      let dx = -5 + Math.random() * 10;
      let dy = -5 + Math.random() * 10;
      let frame = Math.floor(Math.random() * options.frames);

      const bat = document.createElement('div');
      bat.className = 'halloweenBat';
      Object.assign(bat.style, {
        position: 'absolute',
        left: `${x}px`,
        top: `${y}px`,
        zIndex: options.zIndex,
        width: `${options.width}px`,
        height: `${options.height}px`,
        backgroundImage: `url(${options.image})`,
        backgroundRepeat: 'no-repeat',
        opacity: 1,
        transition: 'opacity 1s ease-out' // Smooth fade-out effect
      });
      document.body.appendChild(bat);
      bats.push(bat);

      const move = () => {
        const left = tx - x;
        const top = ty - y;
        const length = Math.max(1, Math.sqrt(left * left + top * top));
        const dLeft = options.speed * (left / length);
        const dTop = options.speed * (top / length);

        dx += (dLeft - dx) / options.flickering;
        dy += (dTop - dy) / options.flickering;

        x = Math.max(0, Math.min(x + dx, innerWidth - options.width));
        y = Math.max(0, Math.min(y + dy, innerHeight - options.height));

        bat.style.left = `${x}px`;
        bat.style.top = `${y}px`;

        if (Math.random() > 0.95) {
          tx = randomPosition('horizontal');
          ty = randomPosition('vertical');
        }
      };

      const animate = () => {
        frame = (frame + 1) % options.frames;
        bat.style.backgroundPosition = `0 ${-frame * options.height}px`;
      };

      const moveInterval = setInterval(move, 40);
      const animateInterval = setInterval(animate, 200);

      intervals.push(moveInterval, animateInterval);
    };

    for (let i = 0; i < options.amount; i += 1) {
      createBat();
    }

    // Set up fade-out after 5 seconds
    const removalTimeout = setTimeout(() => {
      bats.forEach(bat => {
        bat.style.opacity = 0; // Start fade-out transition
      });

      // Remove bats after transition ends (1 second)
      setTimeout(() => {
        bats.forEach(bat => bat.remove());
        intervals.forEach(interval => clearInterval(interval));
      }, 1000);
    }, 5000);

    // Cleanup on component unmount
    return () => {
      clearTimeout(removalTimeout);
      bats.forEach(bat => bat.remove());
      intervals.forEach(interval => clearInterval(interval));
    };
  }, []);

  return null; // This component doesn't render anything directly
};

export default HalloweenBats;
